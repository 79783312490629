// 最外层盒子样式
%out-box {
  // width: (1920rem / 100);
  position: relative;
}

// 1920设计稿，所有的值除以30；

// 内层盒子
%inside-box {
  width: (1200rem / 100);
  margin: 0 auto;
}

.voluteer-container {
  @extend %out-box;
  background: url("https://image.bookgo.com.cn/%20webculture/jm/bg/bg_body.jpg");

  .voluteer-crumbs {
    @extend %inside-box;
    padding-top: (90rem / 100);
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 20px;
    color: #761f1e;
    margin-bottom: (28rem / 100);

    a {
      color: #273143;
      margin-right: 4px;
    }
  }

  .voluteer-info {
    @extend %inside-box;
    margin: 0 auto;

    &-item {
      width: (380rem / 100);
      height: (278rem / 100);
      float: left;
      margin-right: (30rem /100);
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #e8e8e8;

      &:hover {
        box-shadow: 0 (3rem / 100) (6rem / 100)
          rgba($color: #c5c5c5, $alpha: 0.16);
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        height: (223rem / 100);
      }

      &-desc {
        width: 100%;
        position: relative;
        height: (55rem / 100);
        background-color: #fff;
        padding: (14rem / 100) (17rem / 100);

        &-title {
          float: left;
          font-size: (20rem / 100);
        }

        &-number {
          float: right;
          font-size: (20rem / 100);
        }
      }
    }
  }
}
